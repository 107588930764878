import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RoundedGreyBox, IconList, Button } from '@rentecarlo/component-library';
import { Title } from '@organisms/modals/TextModal/styles';
import { useComponentId, ComponentType, useAppSelector } from '@hooks';
import { saveAndGoTo } from '@redux/reducer/quote/save.actions';
import { setProduct } from '@redux/reducer/quote/product.actions';
import { RootState } from '@redux/reducer';
import SparkleIcon from '@assets/teal-sparkles.svg';
import TickIcon from '@assets/black-tick.svg';
import QuoteStep from '@templates/QuoteStep';
import SeamlessPriceDisplay from '@molecules/blocks/SeamlessPriceDisplay';

const TitleContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

const Sparkles = styled.img`
  margin-left: 8px;
`;

const InfoText = styled.div`
  font-family: ${({ theme }) => theme.standard};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  line-height: 24px;
`;

const TextHeaders = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.23px;
  color: ${({ theme }) => theme.textPrimary};
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.standard};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.08px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
`;

const SpecialTitle: React.FC = () => {
  const creator = useComponentId();
  return (
    <TitleContainer>
      <Title id={creator(ComponentType.TEXT, 'specialTitle')}>
        We've got something special for you!
        <Sparkles src={SparkleIcon} alt='sparkle-icon' />
      </Title>
    </TitleContainer>
  );
};

const SeamlessField: React.FC = () => {
  const [showPriceAfterPass, setShowPriceAfterPass] = useState(false);
  const handleToggle = () => {
    setShowPriceAfterPass(!showPriceAfterPass);
  };
  const dispatch = useDispatch();
  const creator = useComponentId();
  const quotePrice = useAppSelector((state) => state.quote.save.price);
  const isFastRepurchase = useSelector((state: RootState) => state.quote.save.isFastRepurchase);
  const productType = useSelector((state: RootState) => state.quote.product.productType);
  const altProduct = useSelector((state: RootState) => state.quote.alternativeProducts);
  const altProductPrice = (altProduct.seamless?.price as number).toFixed(2).toString();
  return (
    <QuoteStep paddingTop='0' titleMarginBottom='0' id={creator(ComponentType.BUTTON, 'continue')}>
      <Container>
        <SpecialTitle />
        <RoundedGreyBox padding={[16, 16, 16, 16]}>
          <InfoText id='productChoice-text-seamlessInfo'>
            Want to hit the road right after passing your driving test and unlock exclusive
            discounts? Try <b>Seamless</b>, our revolutionary new way of doing insurance!
          </InfoText>
        </RoundedGreyBox>
        <IconList
          id='productChoice-component-seamlessInfoList'
          icon={TickIcon}
          items={[
            <Text id='productChoice-text-seamlessInfoListOne'>
              <TextHeaders>All-in-one cover</TextHeaders>
              Stay covered as a learner and after you pass—same policy, hassle-free!
            </Text>,
            <Text id='productChoice-text-seamlessInfoListOne'>
              <TextHeaders>Save big after you pass 🎉</TextHeaders>
              Your first month post-test is at your learner rate!
            </Text>,
            <Text id='productChoice-text-seamlessInfoListTwo'>
              <TextHeaders>Drive safe, save more 🚗</TextHeaders>
              After passing your test, use our telematics app to track your driving and earn
              discounts as your driving improves. <b>No black box!</b>
            </Text>,
            <Text id='productChoice-text-seamlessInfoListThree'>
              <TextHeaders>No strings attached </TextHeaders>
              Cancel anytime—before or after passing—with no hidden fees.
            </Text>,
          ]}
        />
        <SeamlessPriceDisplay
          leftActive={!showPriceAfterPass}
          handleToggle={handleToggle}
          learnerPrice={quotePrice}
          priceAfterPass={altProductPrice}
        />
      </Container>
      <ButtonContainer>
        <Button
          id={creator(ComponentType.BUTTON, 'seamlessOfferContinueWithJustDrive')}
          onClick={() => {
            dispatch(setProduct('seamless'));
            if (isFastRepurchase) {
              dispatch(saveAndGoTo('confirm-details'));
            } else {
              dispatch(saveAndGoTo('important-information'));
            }
          }}
        >
          Continue with JustDrive
        </Button>
        <Button
          id={creator(ComponentType.BUTTON, 'seamlessOfferNoThanks')}
          onClick={() => {
            if (productType === 'seamless') {
              dispatch(setProduct('ldp'));
            }
            if (isFastRepurchase) {
              dispatch(saveAndGoTo('confirm-details'));
            } else {
              dispatch(saveAndGoTo('important-information'));
            }
          }}
          backgroundColor='transparent'
          borderColor='transparent'
          textColor='#171717'
          textHoverColor='#fff'
        >
          No thanks
        </Button>
      </ButtonContainer>
    </QuoteStep>
  );
};

export default SeamlessField;
