import React from 'react';
import { PageModal, RoundedGreyBox, InformationBox, TextLink } from '@rentecarlo/component-library';
import styled from 'styled-components';
import { ComponentType, useComponentId } from '@hooks';
import { Title } from '@molecules/slides/ModalSlide/styles';
import fonts from '@config/fonts';
import colors from '@config/colors';

interface Props {
  show: boolean;
  close: () => void;
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-family: ${fonts.title};
  color: ${colors.dark.title};
  line-height: 36px;
`;

const InfoText = styled.p`
  font-size: 16px;
  font-family: ${fonts.standard};
  color: ${colors.outerSpace};
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 32px;
  font-size: 16px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 8px;
`;

const PaperDocsModal: React.FC<Props> = ({ show, close }) => {
  const closeText = 'Close';
  const idCreator = useComponentId();

  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Title id={idCreator(ComponentType.TITLE, 'PaperDocsModal')}>
        Receive paper copies of your documents
      </Title>
      <Wrapper>
        <RoundedGreyBox margin={[16]} id='paperDocsModal-text'>
          We will always send you your policy documents by email, they will also be available in{' '}
          <TextLink href={`${process.env.REACT_APP_ACCOUNT_URL}/purchases`}>your account</TextLink>{' '}
          and the Veygo app.
        </RoundedGreyBox>
        <InfoText id='paperDocsModal-infotext'>
          If you require paper copies of your documents, please contact{' '}
          <TextLink
            href='mailto:documents@veygo.com'
            id={idCreator(ComponentType.TEXT, 'PaperDocsModal-email')}
          >
            documents@veygo.com
          </TextLink>{' '}
          with the following information:
          <List id={idCreator(ComponentType.TEXT, 'NeededInformation')}>
            <li id={idCreator(ComponentType.TEXT, 'FullName')}>
              The full name of the policy holder
            </li>
            <li id={idCreator(ComponentType.TEXT, 'Policynum')}>The policy number</li>
            <li id={idCreator(ComponentType.TEXT, 'DocsPref')}>
              Whether you would like just the <b>key documents</b> or <b>all your documents</b>
            </li>
          </List>
          <Options>
            <Subtitle id={idCreator(ComponentType.TITLE, 'PaperDocsModal-keydocs')}>
              Key Documents includes:
            </Subtitle>
            <List id={idCreator(ComponentType.TEXT, 'PaperDocsModal-keydocslist')}>
              <li id='payment-text-deliveryPreferenceFormMotor'>Motor proposal confirmation</li>
              <li id='payment-text-deliveryPreferenceFormCertificate'>Certificate of insurance</li>
              <li id='payment-text-deliveryPreferenceFormSchedule'>Policy schedule</li>
            </List>
            You can access the rest of your documents online.
            <Subtitle>All documents includes:</Subtitle>
            <List>
              <li id='payment-text-deliveryPreferenceForm'>
                All documents in the post including the policy booklet (35-40 pages).
              </li>
            </List>
          </Options>
        </InfoText>
        <InformationBox id='payment-text-deliveryPreferenceFormDeliveryTime' type='important'>
          Documents may take 5-7 working days to reach you by post.
        </InformationBox>
      </Wrapper>
    </PageModal>
  );
};

export default PaperDocsModal;
