import React from 'react';
import styled from 'styled-components';
import { useComponentId, ComponentType } from '@hooks';

interface SegmentedControlProps {
  leftOption: string;
  rightOption: string;
  onClick: (isLeftActive: boolean) => void;
  leftActive: boolean;
}

const ControlContainer = styled.div`
  display: flex;
  height: 32px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 0.5px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #cbcbcb;
  background: white;
  position: relative;
  width: 340px;
`;

const SlidingBackground = styled.div<{ leftActive: boolean }>`
  position: absolute;
  top: 0;
  left: ${(props) => (props.leftActive ? '0' : '50%')};
  width: 50%;
  height: 100%;
  background: #191b1f;
  border-radius: 60px;
  transition: left 0.3s;
`;

const Control = styled.button<{ active: boolean }>`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background: transparent;
  color: ${(props) => (props.active ? 'white' : 'black')};
  border: none;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 0;
  transition: color 0.3s;
`;

const Text = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.standard};
  font-weight: bold;
`;

const SegmentedControl: React.FC<SegmentedControlProps> = ({
  leftOption,
  rightOption,
  onClick,
  leftActive,
}) => {
  const creator = useComponentId();

  return (
    <ControlContainer>
      <SlidingBackground leftActive={leftActive} />
      <Control active={leftActive} onClick={() => onClick(true)}>
        <Text id={creator(ComponentType.BUTTON, 'segmentedControlLeft')}>{leftOption}</Text>
      </Control>
      <Control active={!leftActive} onClick={() => onClick(false)}>
        <Text id={creator(ComponentType.BUTTON, 'segmentedControlRight')}>{rightOption}</Text>
      </Control>
    </ControlContainer>
  );
};

export default SegmentedControl;
