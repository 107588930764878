import React from 'react';
import styled from 'styled-components';
import colors from '@config/colors';
import fonts from '@config/fonts';

const TitleContainer = styled.div<{
  backgroundColor: string | undefined;
  paddingTop: string | null;
  marginBottom: string | null;
}>`
  align-items: left;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  padding-top: ${({ paddingTop }) => paddingTop || '50px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '50px'};
`;

const PageTitle = styled.h1`
  color: ${colors.dark.title};
  font-family: ${fonts.title};
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 38px;
`;

/*
current version of styled components doesn't support
extending styles. Reuse the styles from paragraphs/Light
*/
const SubTitle = styled.div`
  color: ${colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  background-color: ${colors.grey.background.light};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-top: 14px;
`;

interface Props {
  backgroundColor?: string | undefined;
  title: string;
  subtitle?: string;
  paddingTop?: string | null;
  marginBottom?: string | null;
}

const StepTitle = ({
  backgroundColor,
  title,
  subtitle = '',
  paddingTop = null,
  marginBottom = null,
}: Props): JSX.Element => {
  return (
    <TitleContainer
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      marginBottom={marginBottom}
    >
      <PageTitle data-amp-unmask id='quote-title'>
        {title}
      </PageTitle>
      {subtitle && (
        <SubTitle data-amp-unmask id='quote-subtitle'>
          {subtitle}
        </SubTitle>
      )}
    </TitleContainer>
  );
};

StepTitle.defaultProps = {
  step: '',
  backgroundColor: 'colors.grey.background.lighter',
};

export default StepTitle;
