import React from 'react';
import QuoteStep from '@templates/QuoteStep';
import { OrSeparator, PillButton } from '@rentecarlo/component-library';
import styled from 'styled-components';
import OutlineButton from '@atoms/buttons/OutlineButton';
import { useOptimizelyTrack } from '@hooks';
import { IpidContainer, Text } from './styles';
import TempCoverCard from './TempCoverCard';

const OrContainer = styled.div`
  width: 100%;
  padding: 16px 0;
`;

type TempCoverCrossSellProps = {
  isQuoteForMyself: boolean;
  tempCoverCrossSellResults: { price: number; passedUW: true };
  updateQuoteToTempCover: (selectDuration: boolean) => void;
};

const TempCoverCrossSell = ({
  isQuoteForMyself,
  tempCoverCrossSellResults,
  updateQuoteToTempCover,
}: TempCoverCrossSellProps): JSX.Element => {
  const sendEvent = useOptimizelyTrack();

  return (
    <QuoteStep
      stepTitle={`Sorry, we can't offer ${isQuoteForMyself ? 'you' : 'them'} a subscription`}
      stepSubtitle={`Unfortunately, we're unable to provide the insurance ${
        isQuoteForMyself ? "you've" : "they've"
      } quoted for on this occasion. Please note that our Customer Care agents are not able to change this decision or provide specific reasoning.`}
      titleMarginBottom='16px'
    >
      <Text id='rejection-text-eligibility'>
        However {isQuoteForMyself ? 'you' : 'they'} are eligible for Pay-as-you-go:
      </Text>
      <TempCoverCard
        tempCoverCrossSellResults={tempCoverCrossSellResults}
        isQuoteForMyself={isQuoteForMyself}
        updateQuoteToTempCover={() => updateQuoteToTempCover(false)}
      />
      <OrContainer>
        <OrSeparator />
      </OrContainer>
      <OutlineButton
        id='rejection-button-selectOwnDuration'
        testId='custom-button'
        onClick={() => {
          updateQuoteToTempCover(true);
          sendEvent('TEMP_COVER_CROSS_SELL_CUSTOM_DURATION');
        }}
      >
        Select your own duration
      </OutlineButton>
      <IpidContainer>
        <PillButton
          id='rejection-button-IPIDPill'
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_ASSETS_URL}/docs/tempcover_v2/Insurance_Product_Information_Document.pdf`,
              '_blank',
            );
          }}
        >
          Read our Product Information Guide
        </PillButton>
      </IpidContainer>
    </QuoteStep>
  );
};

export default TempCoverCrossSell;
