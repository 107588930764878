/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { optimizelyClient } from '@services/clients/optimizely';
import { getCookie } from '@services/cookies';

export interface CookieYesConsentEvent extends Event {
  detail: { accepted: string[]; rejected: string[] };
}

class AmplitudeClient {
  private amplitudeInitialised = false;
  private amplitudeKey = '';
  private sessionReplayTracking = sessionReplayPlugin({ forceSessionTracking: true });

  public async init(amplitudeEnabled: boolean, amplitudeKey: string): Promise<void> {
    this.amplitudeKey = amplitudeKey;

    if (amplitudeEnabled) {
      await this.optIn();
    }

    this.setupConsentListener();
  }

  public trackEvent(eventName: string, eventProperties?: object): void {
    amplitude.track(eventName, { ...eventProperties, app: 'quote' });
  }

  public trackPurchase(productId: string, price: number, eventProperties = {}): void {
    const revenueEvent = new amplitude.Revenue()
      .setProductId(productId)
      .setPrice(price)
      .setQuantity(1)
      .setEventProperties(eventProperties);

    amplitude.revenue(revenueEvent);
  }

  private setupConsentListener(): void {
    document.addEventListener('cookieyes_consent_update', async (event: Event) => {
      const eventData = event as CookieYesConsentEvent;
      const data = eventData.detail;

      if (data.accepted.includes('analytics')) {
        await this.optIn();
        this.trackEvent('web_page_viewed', { pageName: window.location.pathname });
      }
      if (data.rejected.includes('analytics')) {
        this.optOut();
      }
    });
  }

  private optOut(): void {
    if (this.amplitudeInitialised) {
      amplitude.setOptOut(true);
      amplitude.remove(this.sessionReplayTracking.name as string);
    }
  }

  private async optIn(): Promise<void> {
    if (this.amplitudeInitialised) {
      amplitude.setOptOut(false);
      return;
    }

    await this.initAmplitudeWithSessionReplay();

    this.storeDeviceId();
    this.amplitudeInitialised = true;

    this.trackOptimizelyFlags();
  }

  private async initAmplitudeWithSessionReplay(): Promise<void> {
    await amplitude.add(this.sessionReplayTracking).promise;

    await amplitude.init(this.amplitudeKey, {
      serverZone: 'EU',
      autocapture: {
        attribution: false,
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
      defaultTracking: {
        sessions: true,
      },
      optOut: false,
      logLevel: amplitude.Types.LogLevel.Error,
    }).promise;
  }

  private storeDeviceId(): void {
    const deviceId = amplitude.getDeviceId();
    if (deviceId) {
      sessionStorage.setItem('amplitude_device_id', deviceId);
    }
  }

  private trackOptimizelyFlags() {
    // Should really come from the redux store
    const userIdentity = getCookie('user_identity');
    if (!userIdentity) return;

    const enabledFeatures = optimizelyClient.getEnabledFeatures(userIdentity, {
      user_identity: userIdentity,
    });
    if (!enabledFeatures) return;

    const filteredFlags = enabledFeatures.filter((flagName) => flagName.startsWith('TEMP_'));
    this.trackEvent('web_feature_flags', { enabledFeatures: filteredFlags });
  }
}

export default AmplitudeClient;
