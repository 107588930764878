import styled from 'styled-components';

export const Image = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.title};
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  font-weight: bold;

  color: ${({ theme }) => theme.textPrimary};
  margin: 32px 0;
`;

export const ImportantBoxContainer = styled.div`
  margin-bottom: 16px;
`;

export const IpidContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 32px;
`;
