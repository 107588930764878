import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, isValid, change } from 'redux-form';

import { CardModal, InformationBox, Button } from '@rentecarlo/component-library';

import { ComponentType, useComponentId } from '@hooks';
import ReduxFieldValidation from '@services/formValidation';

import { RootState } from '@redux/reducer';

import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import TextInput from '@atoms/inputs/TextInputField';

import { saveCustomer } from '@reducer/account/customer.actions';
import { setDriverField } from '@reducer/quote/driver.actions';

import { Container, Title, FieldContainer } from './assets/styles';

interface Props {
  visible: boolean;
  close: () => void;
  isQuoteForMyself: boolean;
}

const UpdateMobileNumberModal: React.FC<Props & InjectedFormProps> = ({
  visible,
  close,
  handleSubmit,
  submitSucceeded,
  isQuoteForMyself,
}) => {
  const idCreator = useComponentId();
  const dispatch = useDispatch();

  const isFormValid = useSelector(isValid('updateMobileNumberForm'));
  const mobileNumber = useSelector((state: RootState) => state.quote.driver.phoneNumber);
  const productType = useSelector((state: RootState) => state.quote.product.productType);
  const isSeamless = productType === 'seamless';

  const loggedIn = useSelector((state: RootState) => state.account.login.loggedIn);
  const customerSaveLoading = useSelector((state: RootState) => state.account.customer.loading);

  useEffect(() => {
    dispatch(change('updateMobileNumberForm', 'mobileNumber', mobileNumber));
  }, [dispatch, mobileNumber]);

  useEffect(() => {
    if (submitSucceeded && !customerSaveLoading) close();
  }, [close, submitSucceeded, customerSaveLoading]);

  return (
    <CardModal visible={visible} maxWidth='400px'>
      <Container>
        <Title id={idCreator(ComponentType.TITLE, 'updateMobileNumber')}>
          Update {isQuoteForMyself ? 'your' : 'their'} mobile number
        </Title>
        <InformationBox id={idCreator(ComponentType.TEXT, 'updateMobileNumber')} type='important'>
          Please make sure that {isQuoteForMyself ? 'your' : 'their'} mobile number is correct as we
          will use this to activate the{' '}
          {isSeamless ? 'telematics app once you pass' : 'New Driver app'}.
        </InformationBox>
        <FieldContainer>
          <SectionTitle id={idCreator(ComponentType.SUBTITLE, 'updateMobileNumber')}>
            {isQuoteForMyself ? 'Your' : 'Their'} mobile phone number
          </SectionTitle>
          <Field
            id={idCreator(ComponentType.FIELD, 'updateMobileNumber')}
            name='mobileNumber'
            type='tel'
            showInitialError
            placeholder='e.g. 07123456789'
            component={TextInput}
            validate={[ReduxFieldValidation.isMobileNumber]}
          />
        </FieldContainer>
        <Button
          id={idCreator(ComponentType.BUTTON, 'updateMobileNumber')}
          disabled={!isFormValid}
          isLoading={submitSucceeded && customerSaveLoading}
          onClick={handleSubmit((values: Record<string, string>) => {
            dispatch(setDriverField('phoneNumber', values.mobileNumber));

            if (loggedIn) dispatch(saveCustomer());
          })}
        >
          Update mobile phone number
        </Button>
      </Container>
    </CardModal>
  );
};

export default reduxForm({
  form: 'updateMobileNumberForm',
})(UpdateMobileNumberModal);
