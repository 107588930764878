export default (productType: string, isDriverOwner: boolean): string => {
  return (
    {
      tc: 'otc',
      ldp: isDriverOwner ? 'lop' : 'ldp',
      csi: 'dtc',
      newdriver: 'nds',
      seamless: 'ssi',
    }[productType] || ''
  );
};
