import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CognitoClient } from '@rentecarlo/node-amplify-client';
import { useLocation } from 'react-router-dom';
import {
  useOptimizelyFlag,
  ComponentType,
  useComponentId,
  useOptimizelyTrack,
  useAppSelector,
} from '@hooks';
import QuoteStep from '@templates/QuoteStep';
import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import DropDownDisplay from '@atoms/blocks/DropDownDisplay/DropDownDisplay';
import WhatYouNeed from '@atoms/blocks/WhatYouNeed/WhatYouNeed';
import styled from 'styled-components';
import PrivacyPolicyInfo from '@atoms/PrivacyPolicyInfo';
import { Info, LicenceSelector, Panel, P, Button } from '@rentecarlo/component-library';
import ToggleButton from '@molecules/inputs/ToggleButtonWithNoTicker';

import analyticsClient from '@utils/analytics';

import SelectNew from '@molecules/selects/SelectNewField/SelectNew';

import colors from '@config/colors';

import { setCustomerField } from '@redux/reducer/account/customer.actions';

import { NewDriverInfoContainer } from './styles';

const relationToBuyerDefault = {
  name: '',
  value: null,
};

const relationToBuyerValues = [
  { name: 'My child', value: 'child' },
  { name: 'My partner', value: 'partner' },
  { name: 'My friend', value: 'friend' },
  { name: 'Other', value: 'other' },
];

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
`;

const StyledGroup = styled(Group)`
  margin-bottom: 30px;
`;

const SubQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: stretch;

  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }): string => theme.backgroundAlt};
  margin-bottom: 16px;
`;

interface Props {
  licenceType: string;
  productType: string;
  submission: (productType: string) => void;
  updateLicenceType: (licenceType: string) => void;
  setForMyself: (value: string) => void;
  isQuoteForMyself: boolean;
  resetQuote: () => void;
  amplifyLoaded: boolean;
  loggedIn: boolean;
  login: () => void;
}

const LoggedOutStart: React.FC<Props> = ({
  licenceType,
  productType,
  submission,
  updateLicenceType,
  setForMyself,
  isQuoteForMyself,
  resetQuote,
  amplifyLoaded,
  loggedIn,
  login,
}) => {
  useEffect(() => {
    resetQuote();
  }, [loggedIn, resetQuote]);

  const dispatch = useDispatch();

  const relationToBuyerValue = useAppSelector((state) => state.account.customer.relationToBuyer);
  const { name: relationToBuyer } =
    relationToBuyerValues.find((item) => item.value === relationToBuyerValue) ??
    relationToBuyerDefault;

  const sendEvent = useOptimizelyTrack();
  const showWhatYouNeed = useOptimizelyFlag('TEMP_WHATS_NEEDED_FOR_QUOTE');

  const creator = useComponentId();
  const [licenseAutofill, setLicenseAutofill] = useState(licenceType);
  const [renderWhatYouNeed, setRenderWhatYouNeed] = useState(false);

  const allowAllNewDriver = useOptimizelyFlag('TEMP_ALLOW_ALL_NEW_DRIVER');
  const buyingForDropdown = useOptimizelyFlag('TEMP_BUYING_FOR_DROPDOWN');

  const location = useLocation();

  const handleSubmission = () => {
    if (
      !renderWhatYouNeed &&
      showWhatYouNeed.enabled &&
      showWhatYouNeed.variationKey === 'FULL_PAGE'
    ) {
      setRenderWhatYouNeed(true);
    } else {
      submission(productType);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search).get('licence-type');
    const validLicenceTypes = ['uk_prov', 'uk_manual'];
    const autofill =
      queryParams && validLicenceTypes.includes(queryParams) ? queryParams : licenceType;
    setLicenseAutofill(autofill);

    if (allowAllNewDriver.clientReady && !allowAllNewDriver.enabled) {
      if (productType === 'newdriver' && amplifyLoaded && !loggedIn) {
        // Can't rely on just the loggedIn value in the state (when users enter the QE) as
        // start renders before the init (login) saga finishes
        CognitoClient.getCurrentSession().catch(() => {
          login();
        });
      }
    }
  }, [
    amplifyLoaded,
    productType,
    loggedIn,
    login,
    location,
    licenceType,
    allowAllNewDriver.clientReady,
    allowAllNewDriver.enabled,
  ]);

  return showWhatYouNeed.enabled &&
    showWhatYouNeed.variationKey === 'FULL_PAGE' &&
    renderWhatYouNeed ? (
    <QuoteStep
      id='start-component-WhatsNeeded'
      stepTitle='What you need for your quote'
      nextAction={() => {
        submission(productType);
        sendEvent('what_you_need_next');
      }}
      showButton
      nextLabel='Next'
      submitError={null}
    >
      <WhatYouNeed />
      <Info id='start-text-foreignLicences'>
        We currently do not accept paper licences, Northern Irish licences or licences issued
        outside of Great Britain.
      </Info>
    </QuoteStep>
  ) : (
    <QuoteStep
      id='start-component-quoteStepLoggedOut'
      paddingTop='50px'
      titleMarginBottom={productType === 'newdriver' ? '20px' : null}
      stepTitle='Start your quote...'
      nextDisabled={
        licenceType === 'non_uk' || (productType === 'newdriver' && !allowAllNewDriver.clientReady)
      }
      submitError={null}
    >
      <div id='start-component-selectBuyingFor'>
        <SectionTitle id='title-selectBuyingFor'>Who are you buying for?</SectionTitle>
        <ToggleButton
          value={isQuoteForMyself ? 'true' : 'false'}
          groupName='selectBuyingFor'
          leftLabel='Myself'
          rightLabel='Someone Else'
          page='Start'
          onSelect={(e: { target: { value: string } }) => setForMyself(e.target.value === 'true')}
        />
        {buyingForDropdown.enabled && !isQuoteForMyself && (
          <SubQuestionContainer>
            <SectionTitle id='title-relationToBuyer'>Who are you buying for?</SectionTitle>
            <SelectNew
              id='start-field-relationToBuyer'
              placeholder='Please choose'
              value={relationToBuyer}
              borderBottomColor={relationToBuyer ? colors.green.selected : colors.athensGray}
              options={relationToBuyerValues}
              onChange={(name: string) => {
                const { value } =
                  relationToBuyerValues.find((item) => item.name === name) ??
                  relationToBuyerDefault;
                dispatch(setCustomerField('relationToBuyer', value));
                analyticsClient.trackEvent('web_selected_relation_to_buyer', { value });
              }}
            />
          </SubQuestionContainer>
        )}
      </div>
      {productType === 'newdriver' && (
        <NewDriverInfoContainer>
          <Panel>
            <P id='start-paragraph-quoteStep' fontSize={16}>
              Welcome to New Driver insurance, just answer a few short questions and we will be able
              to get a price for you...
            </P>
          </Panel>
        </NewDriverInfoContainer>
      )}
      <StyledGroup id='start-component-group'>
        <SectionTitle id='start-title-licenceType'>
          {isQuoteForMyself ? 'What is your licence type ?' : 'What is their licence type ?'}
        </SectionTitle>
        <LicenceSelector
          defaultType={licenseAutofill}
          selectedType={licenceType}
          displayedLicences={productType === 'newdriver' ? ['uk_manual'] : undefined}
          onChange={updateLicenceType}
        />
        {showWhatYouNeed.enabled && showWhatYouNeed.variationKey === 'FULL_PAGE' ? null : (
          <Info id='start-text-foreignLicences'>
            We currently do not accept paper licences, Northern Irish licences or licences issued
            outside of Great Britain.
          </Info>
        )}
        {showWhatYouNeed.enabled && showWhatYouNeed.variationKey === 'DROPDOWN' && (
          <DropDownDisplay
            title='What you need for your quote'
            onClick={() => sendEvent('what_you_need_dropdown_click')}
          >
            <WhatYouNeed />
          </DropDownDisplay>
        )}
      </StyledGroup>
      <PrivacyPolicyInfo />
      <ButtonContainer>
        <Button
          id={creator(ComponentType.BUTTON, 'startSignIn')}
          onClick={(): void => {
            sendEvent('start_sign_in');
            login();
          }}
          backgroundColor='transparent'
          borderColor='#171717'
          textColor='#171717'
          textHoverColor='#fff'
        >
          Sign In
        </Button>
        <Button
          id={creator(ComponentType.BUTTON, 'startContinueAsGuest')}
          onClick={() => {
            handleSubmission();
            sendEvent('start_continue_as_guest');
          }}
        >
          Continue As Guest
        </Button>
      </ButtonContainer>
    </QuoteStep>
  );
};

export default LoggedOutStart;
