import { ProductType } from '@utils/types';
import { AlternativeProductActions, types } from './alternativeProducts.actions';
import { types as quoteTypes, QuoteActions } from './actions';

export type AlternativeProductTrueResult = { passedUW: true; price: number };
type AlternativeProductFalseResult = { passedUW: false; price: null };
export type AlternativeProductResult = AlternativeProductTrueResult | AlternativeProductFalseResult;

export type AlternativeProducts = {
  [key in ProductType | 'tempcover']?: AlternativeProductResult;
};

// Reducer
export const initialState: AlternativeProducts = {};

export default function alternativeProducts(
  state: AlternativeProducts = initialState,
  action = {} as AlternativeProductActions | QuoteActions,
): AlternativeProducts {
  switch (action.type) {
    case types.SET_ALTERNATIVE_PRODUCT_RESULT: {
      const newState = {
        ...state,
        [action.productType]: action.result,
      };
      return newState;
    }
    case types.CLEAR_ALTERNATIVE_PRODUCTS: {
      return {};
    }
    case quoteTypes.RESET_QUOTE: {
      return initialState;
    }
    default:
      return state;
  }
}
