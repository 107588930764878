import React, { useState, useEffect } from 'react';
import { Location } from 'history';
import styled from 'styled-components';

import { Field, InjectedFormProps } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import QuoteStep from '@templates/QuoteStep';
import Group from '@atoms/layout/form/Group';
import OwnerDetailsModal from '@organisms/modals/OwnerDetailsModal';
import CarSearch from '@organisms/blocks/CarSearch';
import OwnerSummary from '@organisms/blocks/OwnerSummary';
import ReasonForCover from '@organisms/fields/ReasonForCover';
import { RootState } from '@redux/reducer';

import { FieldContainer } from '@rentecarlo/component-library';

import { logNoMainInsuranceEvent, pushReasonForCover } from '@services/ecommerceTracking';
import { useOptimizelyFlag } from '@hooks';
import overnightLocationOptions from '@config/overnightLocationOptions';
import DropDownField from '@atoms/inputs/DropDownField';
import ReduxFieldValidation from '@services/formValidation';
import { setNewDriverField } from '@redux/reducer/quote/newdriver.actions';
import { Car } from './CarDetails.container';

import AIQuestionField from './fields/AIQuestionField';

type LocationType = Location & {
  state: {
    scrollToOwner?: boolean;
  };
};

export interface ValueProps {
  productType: string;
  tempcoverModalOpen: boolean;
  relationshipModalOpen: boolean;
  alternativeInsurers: Array<{ name: string; value: string }>;
  otherInsuranceSelected: boolean;
  getInsurersName: string;
  hasInsurance: string;
  valid: boolean;
  car: Car;
  isLoggedIn: boolean;
  termsOfCoverConfirmed: boolean;
  ownerFirstName: string;
  ownerSurname: string;
  ownerBirthdate: string;
  ownerEmail: string;
  ownerRelationship: string;
  hasOwnerDetails: boolean;
  formErrors: Record<string, unknown>;
  form: Record<string, unknown>;
  submitFailed: boolean;
  syncErrors: Record<string, unknown>;
  location: LocationType;
  isDriverOwner: boolean;
  isSubscription: boolean;
  selectedRelationship: string;
  isQuoteForMyself: boolean;
  isFastRepurchase: boolean;
}

export interface DispatchProps {
  openRelationshipModal: (e: React.SyntheticEvent) => void;
  closeRelationshipModal: () => void;
  setHasInsurance: (value: string) => void;
  toggleTempcoverPersonalVehicle: () => void;
  resetMainInsuranceQuestion: () => void;
  submitToQuote: (
    values: Record<string, unknown>,
    car: Car,
    loggedIn: boolean,
    productType: string,
    isDriverOwner: boolean,
    isFastRepurchase: boolean,
  ) => void;
  toggleTermsOfCover: () => void;
  submitOwnerDetails: (values: Record<string, unknown>, featureToggle: boolean) => void;
  initaliseOwnerDetailsForm: (fields: Record<string, unknown>) => void;
  resetPageState: () => void;
}

export const SaveCarContainer = styled.div`
  margin-top: -44px;
  margin-bottom: 30px;
`;

const CarDetails: React.FC<ValueProps & DispatchProps & InjectedFormProps> = ({
  formErrors,
  isDriverOwner,
  productType,
  car,
  hasOwnerDetails,
  selectedRelationship,
  submitToQuote,
  isLoggedIn,
  tempcoverModalOpen,
  relationshipModalOpen,
  valid,
  handleSubmit,
  submitOwnerDetails,
  form,
  submitFailed,
  setHasInsurance,
  alternativeInsurers,
  getInsurersName,
  toggleTempcoverPersonalVehicle,
  hasInsurance,
  otherInsuranceSelected,
  isSubscription,
  termsOfCoverConfirmed,
  toggleTermsOfCover,
  initaliseOwnerDetailsForm,
  resetPageState,
  location,
  isQuoteForMyself,
  isFastRepurchase,
}) => {
  const dispatch = useDispatch();
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [ownerDetailsError, setOwnerDetailsError] = useState('');
  const fullState = useSelector((state: RootState) => state);
  const stepTitle = 'Car details';

  const isSeamlessEnabled = useOptimizelyFlag('TEMP_ENABLE_SEAMLESS').enabled;

  useEffect(() => {
    resetPageState();
    if (termsOfCoverConfirmed) {
      toggleTermsOfCover();
    }

    initaliseOwnerDetailsForm();

    const { state } = location;
    if (state && state.scrollToOwner) {
      const scrollElement = document.getElementById('owner-details-section') || document.body;
      const scrollLocation = scrollElement.getBoundingClientRect().y;
      window.scrollTo(0, scrollLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ownerDetailError = selectedRelationship === null && !isDriverOwner ? 'Required' : '';

    setOwnerDetailsError(ownerDetailError);
  }, [selectedRelationship, isDriverOwner]);

  const getFormErrors = (): Record<string, unknown> => {
    let mergedFormErrors = {};

    if (formErrors && ownerDetailsError) {
      mergedFormErrors = { ...formErrors, ownerDetails: ownerDetailsError };
    } else {
      mergedFormErrors = formErrors || { ownerDetails: ownerDetailsError };
    }

    return mergedFormErrors;
  };

  const showInsuranceErrors = (): boolean => {
    let shouldShowError = false;

    if (
      (submitFailed || submitError) &&
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance !== 'invalid_choice_right'
    ) {
      shouldShowError = !!(
        submitFailed &&
        formErrors &&
        (formErrors.hasInsurance || formErrors.mainInsurer)
      );
    } else if ((submitFailed || submitError) && formErrors && formErrors.isNamedDriver) {
      shouldShowError = !!(submitFailed && formErrors && formErrors.isNamedDriver);
    } else if ((submitFailed || submitError) && formErrors && formErrors.mainInsurer) {
      shouldShowError = !!(submitFailed && formErrors && formErrors.mainInsurer);
    } else if (
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance === 'invalid_choice_right'
    ) {
      shouldShowError = !!(formErrors && formErrors.hasInsurance);
    }

    return shouldShowError;
  };

  const errorMessageToShowHasInsurance = (): string => {
    let errorMessage = '';

    if (
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance !== 'invalid_choice_right'
    ) {
      errorMessage = formErrors.hasInsurance;
    } else if (formErrors && formErrors.mainInsurer) {
      errorMessage = formErrors.mainInsurer;
    } else if (formErrors && formErrors.isNamedDriver) {
      errorMessage = formErrors.isNamedDriver;
    } else if (
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance === 'invalid_choice_right'
    ) {
      errorMessage = 'This cover is only valid alongside a main insurance policy';
    }

    return errorMessage;
  };

  const logInsuranceSelectionEventIfNecessary = (value: string): void => {
    if (productType === 'ldp' && value === 'false' && isDriverOwner) {
      logNoMainInsuranceEvent();
    }
  };

  const validateNextButton = (): boolean => {
    if (isDriverOwner) {
      return !!car;
    }

    return !!car && hasOwnerDetails;
  };

  const toNextStep = (values: Record<string, unknown>): void => {
    if (!ownerDetailsError) {
      if (isSeamlessEnabled && productType === 'ldp' && isSubscription) {
        dispatch(setNewDriverField('overnightLocation', values.overnightLocation));
      }
      submitToQuote(values, car, isLoggedIn, productType, isDriverOwner, isFastRepurchase);
    } else {
      setSubmitError(true);
    }
  };

  return (
    <QuoteStep
      id='car-component-quoteStep'
      nextAction={handleSubmit((values: Record<string, string>) => {
        toNextStep(values);
      })}
      stepTitle={stepTitle}
      stepSubtitle={`Give this a once over to check we've got everything right about the car ${
        isQuoteForMyself ? 'you' : 'they'
      } want to cover.`}
      height={tempcoverModalOpen || relationshipModalOpen ? '1500' : ''}
      formErrors={getFormErrors()}
      form={form}
      submitError={(submitFailed || submitError) && (!valid || ownerDetailsError)}
      showButton={validateNextButton()}
      triggerGAEvent={() => pushReasonForCover(fullState, stepTitle)}
    >
      <Group>
        <CarSearch submitFailed={submitFailed || submitError} isQuoteForMyself={isQuoteForMyself} />
      </Group>
      {car && (
        <>
          <AIQuestionField
            showErrors={showInsuranceErrors()}
            error={errorMessageToShowHasInsurance()}
            onSetHasInsurance={(value: string): void => {
              setHasInsurance(value);
              logInsuranceSelectionEventIfNecessary(value);
            }}
            onSetTempcoverPersonalVehicle={toggleTempcoverPersonalVehicle}
            alternativeInsurers={alternativeInsurers}
            formErrors={formErrors}
            getInsurersName={getInsurersName}
            hasInsurance={hasInsurance}
            isDriverOwner={isDriverOwner}
            otherInsuranceSelected={otherInsuranceSelected}
            productType={productType}
            isQuoteForMyself={isQuoteForMyself}
          />
          {isSeamlessEnabled && productType === 'ldp' && isSubscription && (
            <FieldContainer>
              <Field
                name='overnightLocation'
                label='Where will the car be parked overnight?'
                id='cardetails-field-overnightLocationDropdown'
                component={DropDownField}
                placeholder='Select where the car will be parked'
                options={overnightLocationOptions}
                errorText='Overnight location is required.'
                validate={[ReduxFieldValidation.isRequired]}
              />
            </FieldContainer>
          )}
          {productType === 'tc' && !isSubscription && (
            <ReasonForCover
              formName='carForm'
              showError={submitFailed && formErrors && !!formErrors.tempcoverReason}
              error={formErrors && (formErrors.tempcoverReason as string)}
            />
          )}
        </>
      )}
      {!isDriverOwner && !!car && (
        <div id='owner-details-section' data-testid='owner-details-section'>
          <Group>
            <FieldContainer
              id='car-title-ownerDetails'
              showErrorContainer={(submitFailed || submitError) && !!ownerDetailsError}
              showError={(submitFailed || submitError) && !!ownerDetailsError}
              error={ownerDetailsError}
            >
              <OwnerSummary setOwnerModalVisibility={(): void => setShowOwnerModal(true)} />
            </FieldContainer>
          </Group>

          <OwnerDetailsModal
            show={showOwnerModal}
            close={(): void => setShowOwnerModal(false)}
            submitOwnerDetails={submitOwnerDetails}
          />
        </div>
      )}
    </QuoteStep>
  );
};

export default CarDetails;
