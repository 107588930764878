import AmplitudeClient from './amplitude';

type ClientConfig = { enabled: boolean; apiKey: string };

type InitSettings = { amplitude: ClientConfig };

export class AnalyticsClient {
  private amplitudeClient: AmplitudeClient = new AmplitudeClient();

  private getGlobalProperties?: () => object;

  public async init(setup: InitSettings): Promise<void> {
    await this.amplitudeClient.init(setup.amplitude.enabled, setup.amplitude.apiKey);
  }

  public addGlobalProperties(globalPropertiesCallback: () => object): void {
    this.getGlobalProperties = globalPropertiesCallback;
  }

  public trackEvent(eventName: string, eventProperties?: object): void {
    const properties = {
      ...eventProperties,
      ...this.getGlobalProperties?.(),
    };
    this.amplitudeClient.trackEvent(eventName, properties);
  }

  public trackPurchase(product: string, price: number, eventProperties = {}): void {
    this.amplitudeClient.trackPurchase(product, price, eventProperties);
  }
}

const analyticsClient = new AnalyticsClient();

export default analyticsClient;
