/*
 * Reselectors for Quote conditions.
 * Reselectors are memoized selectors.
 * Selectors as the functions that retrieve snippets of the Redux state for our React components.
 * Using memoization, we can prevent unnecessary rerenders and recalculations of derived data.
 * Importantly we can keep business logic out of components.
 */
import { createSelector } from 'reselect';
import {
  csiExcessData,
  csiConfirmations,
  ldpExcessData,
  tempcoverExcessData,
  newDriverExcessData,
  ldpConfirmations,
  subsConfirmations,
} from '@config/excesses';
import { capitaliseFirstLetter } from '@services/formatString';
import { getBreakdownCoverPrice } from '@services/ancillary';

export const licenceType = (state) => state.quote.licence.type;
const cassieType = (state) => state.quote.licence.cassieType;
const driverPostcode = (state) => state.quote.driver.address.postcode;
const owner = (state) => state.quote.owner;
const quickSelectDuration = (state) => state.quote.duration.endChoiceSelected;
const selectedExcess = (state) => state.quote.importantInformation.excessSelected;
const defaultExcess = (productType) => {
  const excess = {
    csi: 750,
    ldp: 250,
    tc: 750,
    newdriver: 750,
  };
  return excess[productType];
};
const driver = (state) => state.quote.driver;
const product = (state) => state.quote.product;

const postcodeRegex = '^[A-Za-z]{1,2}[A-Za-z0-9]{1,2} ?[0-9][A-Za-z]{2}$';
export const isPostcodeValid = (postcode) => !!postcode && postcode.match(postcodeRegex) !== null;

export const hasBoughtBeforeSelector = (state) =>
  state.account.login.loggedIn && state.account.customer.quotes.length > 0;

export const crossSaleInitialPriceSelector = (
  state,
  initialPrice,
  price,
  isBreakdownCover = false,
) => {
  // #13664
  const { hasConversionBonus, crossaleDiscountFactor } = state.account.customer;
  const { validDiscountCode } = state.quote.save;

  if (!hasConversionBonus || !price) return null;

  if (isBreakdownCover) {
    const previousPriceWithBreakdown = (newPrice, factor) => {
      const breakdownPrice = getBreakdownCoverPrice(
        state.quote.importantInformation.finalPricesByExcess,
      );

      return (
        (parseFloat(newPrice) - parseFloat(breakdownPrice)) / parseFloat(factor) +
        parseFloat(breakdownPrice)
      ).toFixed(2);
    };

    if (validDiscountCode) {
      return previousPriceWithBreakdown(initialPrice, crossaleDiscountFactor);
    }

    return previousPriceWithBreakdown(price, crossaleDiscountFactor);
  }

  const previousPrice = (newPrice, factor) =>
    (parseFloat(newPrice) / parseFloat(factor)).toFixed(2);

  if (validDiscountCode) {
    return previousPrice(initialPrice, crossaleDiscountFactor);
  }

  return previousPrice(price, crossaleDiscountFactor);
};

export const isDriverPostcodeValid = createSelector([driverPostcode], isPostcodeValid);

export const excesses = createSelector([product], ({ productType }) => {
  switch (productType) {
    case 'csi':
      return csiExcessData;
    case 'ldp':
      return ldpExcessData;
    case 'tc':
      return tempcoverExcessData;
    case 'newdriver':
      return newDriverExcessData;
    default:
      return csiExcessData;
  }
});

export const confirmations = createSelector([product], ({ productType, isSubscription }) => {
  if (isSubscription) {
    return subsConfirmations;
  }
  if (productType === 'ldp') {
    return ldpConfirmations;
  }
  return csiConfirmations;
});

export const productEmail = 'https://www.veygo.com/contact/';

export const getDriverName = createSelector(
  [driver],
  ({ title, firstName, lastName }) =>
    `${capitaliseFirstLetter(title)} ${capitaliseFirstLetter(firstName)} ${capitaliseFirstLetter(
      lastName,
    )}`,
);

export const getProductType = createSelector([product], ({ productType }) => productType);
export const getPreviousProductType = createSelector(
  [product],
  ({ previousProductType }) => previousProductType,
);
export const getIsSubscription = createSelector([product], ({ isSubscription }) => isSubscription);

export const getDriverOwner = createSelector([driver], ({ isDriverOwner }) => isDriverOwner);

export const getProductName = createSelector([product], ({ productType }) => {
  switch (productType) {
    case 'csi':
      return 'Car Sharing Insurance';
    case 'ldp':
      return 'Learner Driver';
    case 'tc':
      return 'Temporary Cover Insurance';
    case 'seamless':
      return 'Seamless Subscription Insurance';
    default:
      return 'Car Sharing Insurance';
  }
});

export const getOwnerName = createSelector(
  [owner],
  ({ firstName, surname }) =>
    `${capitaliseFirstLetter(firstName)} ${capitaliseFirstLetter(surname)}`,
);

export const isLicenceNotFound = createSelector([cassieType], (type) => type === 'N');
export const isLicenceExpired = createSelector([cassieType], (type) => type === 'E');

export const getIsQuickSelectDurationSelected = createSelector(
  [quickSelectDuration],
  (duration) => !!duration,
);

export const getExcessForProduct = createSelector(
  [product, selectedExcess],
  ({ productType }, excess) => excess || defaultExcess(productType),
);

export const getDefaultExcessForProduct = createSelector([product], ({ productType }) =>
  defaultExcess(productType),
);

export const getLast16CharactersOfDLN = (state) =>
  state.quote.licence?.licenceNumber ? state.quote.licence.licenceNumber.substring(11) : '';

export const getQuoteSavedPrice = (state) => state.quote?.save?.price;
export const getQuoteSavedUUID = (state) => state.quote?.save?.uuid;

export const getHasPassedTest = (state) => state.quote.drivingTest.hasPassed;
