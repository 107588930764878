import { call, put, select, takeEvery, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import dayjs from 'dayjs';
import { getProduct } from '@sagas/routing';
import { stringToFloat } from '@services/convertString';
import { delay } from '@services/polling';
import {
  logEcommerceEvent,
  logConversionEvent,
  logPaymentComplete,
  paymentCompleteSelector,
  trackDuration,
} from '@services/ecommerceTracking';
import { quote as quoteApi, webLog as webLogApi } from '@services/api';
import { quoteApi as quoteRtk } from '@services/api/quoteRtk';
import { getCookie } from '@services/cookies';
import { sendEvent } from '@reducer/analytics/actions';
import sendOptimizelyEvent from '@services/sendOptimizelyEvent';
import { optimizelyClient } from '@services/clients/optimizely';
import { getOptimizelyUserIdentity, optimizelyOverrideAttributes } from '@reselectors/optimizely';
import { ConversionProject } from '@config/constants';
import payment from '@redux/reducer/quote/payment';
import { getQuoteJourneyDuration } from '@utils/quoteJourneyDuration';

import analyticsClient from '@utils/analytics';
import convertProductType from '@services/convertProductType';

import handleError from '../utils/errorHandler';
import {
  types,
  paymentSuccess,
  payFailure,
  finalChecksPassed,
  finalChecksFailed,
  setPaymentField,
  pollForQuoteStatus,
  stripeError,
} from '../reducer/quote/payment.actions';
import { setSaveField } from '../reducer/quote/save.actions';

import { resetQuote } from '../reducer/quote/actions';

export const pollingErrorMessages = {
  ERROR_TRY_AGAIN: 'An error has occurred, please try again.',
  ERROR_PAYMENT_PROCESSED_BUT_ERROR_OCCURRED:
    'Your payment has been processed but an error has occurred, please contact Veygo',
};

const isPaymentAlreadyTakenError = (error) => error && 'code' in error && error.code === 'SAP001';

export function* pollQuoteStatus() {
  const pollingStarted = dayjs();
  const quoteUuid = yield select((state) => state.quote.save.uuid);
  const price = yield select((state) => state.quote.save.price);

  if (!quoteUuid) {
    return; // If customer resets quote while polling is ongoing, prevent checks
  }

  const POLLING_DURATION_MILLIS = 45000;
  const POLLING_INTERVAL_MILLIS = 2500;
  let pollingRounds = POLLING_DURATION_MILLIS / POLLING_INTERVAL_MILLIS;
  let processingStatusResponse = null;

  try {
    while (pollingRounds > 0) {
      pollingRounds -= 1;
      yield call(delay, POLLING_INTERVAL_MILLIS);
      try {
        processingStatusResponse = yield call(quoteApi.checkQuoteStatus, quoteUuid);
      } catch (error) {
        // if polling is done, rethrow fetch error so weblog gets original error message
        if (pollingRounds === 0) {
          throw error;
        }
        handleError(error);
        // eslint-disable-next-line no-continue
        continue;
      }
      if (processingStatusResponse.is_purchased) {
        yield put(paymentSuccess({ uuid: quoteUuid }));
        const pollingEnded = dayjs();
        if (window.newrelic) {
          window.newrelic.addPageAction('Quote purchased', {
            quoteUuid,
            price,
            timeToCompletePurchase: pollingEnded - pollingStarted,
          });
        }
        return;
      }
      if (pollingRounds === 0) {
        const pollingError = 'Polling timeout of 45 secs on quote status';
        yield call(webLogApi.sendError, pollingError, {
          error_message: pollingError,
          service: 'payments',
          quote_uuid: quoteUuid,
        });
        throw new Error(pollingError);
      }
    }
  } catch (error) {
    let errorMessage;
    handleError(error);

    if (error instanceof Error) {
      errorMessage = error.toString();
    } else {
      errorMessage = JSON.stringify(error);
    }

    yield call(webLogApi.sendError, 'Failure to poll for Quote status', {
      error_message: errorMessage,
      service: 'payments',
      quote_uuid: quoteUuid,
      payments_quote_alert: true,
      description: `Check if purchased and has documents first. The amount refundable is ${price}`,
    });
    yield put(payFailure(pollingErrorMessages.ERROR_PAYMENT_PROCESSED_BUT_ERROR_OCCURRED));
    yield put(resetQuote());
    yield put(push('/processing-error'));
  }
}

export function* requestFinalChecks(action) {
  const quoteUuid = yield select((state) => state.quote.save.uuid);
  const { paymentMethod } = action;
  const { id } = paymentMethod;

  if (!quoteUuid || !paymentMethod) {
    return; // If customer resets quote while polling is ongoing, prevent checks
  }

  try {
    yield put(setPaymentField('confirmationToken', id));
    const result = yield call(quoteApi.makeFinalCheck, quoteUuid, paymentMethod);
    const { reasons, payment_intent } = result; // eslint-disable-line camelcase

    if (!reasons || reasons.some((reason) => reason.reason === 'pss')) {
      yield put(finalChecksPassed());
      // eslint-disable-next-line camelcase
      if ('error' in payment_intent) {
        yield put(stripeError(payment_intent.error)); // eslint-disable-line camelcase
      }

      const { client_secret, status } = payment_intent; // eslint-disable-line camelcase
      yield put(setPaymentField('paymentIntent', { clientSecret: client_secret, status })); // eslint-disable-line camelcase
      return;
    }

    yield put(finalChecksFailed());
    yield put(setSaveField('rejectionReasons', reasons));
    yield put(push('/rejection'));
  } catch (error) {
    let errorMessage;
    handleError(error);

    if (error instanceof Error) {
      errorMessage = error.toString();
    } else {
      errorMessage = JSON.stringify(error);
    }

    if (isPaymentAlreadyTakenError(error)) {
      const price = yield select((state) => state.quote.save.price);

      yield call(
        webLogApi.sendError,
        'Customer attempted to pay for an already paid for subscription',
        {
          error_message: errorMessage,
          service: 'payments',
          quote_uuid: quoteUuid,
          payments_quote_alert: true,
          description: `Check if purchased and has documents first. The amount refundable is ${price}`,
        },
      );
      yield put(payFailure(pollingErrorMessages.ERROR_PAYMENT_PROCESSED_BUT_ERROR_OCCURRED));
      yield put(resetQuote());
      yield put(push('/processing-error'));
      return;
    }

    yield put(resetQuote());
    yield put(push('/error'));
  }
}

export const createExtendedPaymentInfo = (paymentInfo, data = {}) => ({
  ...paymentInfo,
  ecommerce: {
    ...paymentInfo.ecommerce,
    isFirstPurchase: data.isFirstPurchase ?? null,
    numberOfPolicies: data.numberOfPolicies ?? null,
  },
});

export function* paymentSuccessSaga() {
  try {
    const driverEmail = yield select((state) => state.quote.driver.email);
    const price = stringToFloat(yield select((state) => state.quote.save.price));
    const quoteUuid = yield select((state) => state.quote.save.uuid);
    const duration = yield select((state) => state.quote.duration.hours);
    const isSubscription = yield select((state) => state.quote.product.isSubscription);

    const isQuoteForMyself = yield select((state) => state.account.customer.isQuoteForMyself);
    const relationToBuyer = yield select((state) => state.account.customer.relationToBuyer);

    yield trackDuration();

    // Determine product slug
    const productType = yield select((state) => state.quote.product.productType);
    // Important: learner-driver and car-sharing are important variables tracked in
    // GA. Check with marketing before changing them.
    let product = 'car-sharing';
    if (productType === 'ldp') {
      product = 'learner-driver';
    }
    logEcommerceEvent(quoteUuid, price, product);

    const paymentInfo = yield select(paymentCompleteSelector);
    let checkoutData;
    try {
      const promise = yield put(yield call(quoteRtk.endpoints.checkout.initiate, quoteUuid));

      // Wait for the checkout success action to be dispatched
      yield take(quoteRtk.endpoints.checkout.matchFulfilled);

      checkoutData = yield select(quoteRtk.endpoints.checkout.select(quoteUuid));
      const { data } = checkoutData;
      const extendedPaymentInfo = createExtendedPaymentInfo(paymentInfo, data);
      logPaymentComplete(extendedPaymentInfo, 'Payment complete');

      logConversionEvent(price, product, driverEmail, data.isFirstPurchase, data.numberOfPolicies);
    } catch (error) {
      const extendedPaymentInfo = createExtendedPaymentInfo(paymentInfo);
      logPaymentComplete(extendedPaymentInfo, 'Payment complete');
      logConversionEvent(price, product, driverEmail);
    }

    // Send event to GA
    yield put(
      sendEvent(ConversionProject.ConversionOptimization, {
        featureFlagName: ConversionProject.PAYMENT_COMPLETE,
        featureFlagValue: 'payment-complete',
      }),
    );
    const overrideAttributes = yield select(optimizelyOverrideAttributes);
    const userIdentity = yield select(getOptimizelyUserIdentity);
    const isFastRepurchase = yield select((state) => state.quote.save.isFastRepurchase);
    sendOptimizelyEvent(
      'quote_purchased',
      { ...overrideAttributes, isFastRepurchase },
      { value: getQuoteJourneyDuration() },
    );
    sessionStorage.removeItem('quoteJourneyStart');

    const convertedProductType = convertProductType(productType).toUpperCase();
    const isCarOwner = yield select((state) => state.quote.driver.isDriverOwner);

    yield analyticsClient.trackPurchase(convertedProductType, price, {
      extra_item_type: convertedProductType,
      extra_is_subscription: isSubscription,
      extra_is_fast_repurchase: isFastRepurchase,
      extra_buying_for: isQuoteForMyself ? 'myself' : relationToBuyer ?? 'someone-else',
      extra_is_first_purchase: checkoutData?.data?.isFirstPurchase,
      extra_is_car_owner: isCarOwner,
      extra_duration_hours: duration,
    });

    // The bpURLParameter is a hashed value that Buyapowa grabs from the URL and unencrypts to track referrals.
    // It originates from the tracking link that the referee gets from the referrer.
    // We then store that as a cookie until the purchase is made.
    // It is then passed to the thank you page URL so that the Buyapowa.track function can use it.
    const bpURLParameter = getCookie('bp_e');
    if (bpURLParameter !== undefined) {
      yield put(push(`/thank-you?bp_e=${bpURLParameter}`));
    } else {
      yield put(push('/thank-you'));
    }
    yield put(resetQuote());
  } catch (error) {
    handleError(error);
  }
}

export function* handleStripeError(action) {
  if (action.error.type === 'card_error') {
    // Usually the bank declining payment
    yield put(finalChecksFailed());
    yield put(payFailure(action.error.message, action.error.code, action.error.decline_code));
  } else if (
    action.error.type === 'invalid_request_error' &&
    action.error.code === 'payment_intent_unexpected_state'
  ) {
    // We have cancelled the payment intent after 4 attempts to pay.
    yield put(push('/rejection'));
  } else {
    yield handleError(action.error);
    yield put(finalChecksFailed());
    yield put(payFailure('An error has occurred, please try again.'));
  }

  yield analyticsClient.trackEvent('web_payment_failed', {
    error_type: action.error.type,
    error_code: action.error.code,
    decline_code: action.error.decline_code,
  });
}

export function* watchPaymentRequest() {
  yield takeEvery(types.PAYMENT_REQUEST_STRIPE_POLL, pollQuoteStatus);
  yield takeEvery(types.PAYMENT_STRIPE_ERROR, handleStripeError);
  yield takeEvery(types.PAYMENT_REQUEST_FINAL_CHECKS, requestFinalChecks);
}

export function* watchPaymentSuccess() {
  yield takeEvery(types.PAYMENT_SUCCESS, paymentSuccessSaga);
}
