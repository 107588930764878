import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { InformationBox } from '@rentecarlo/component-library';
import { useLocation } from 'react-router-dom';

import QuoteStep from '@templates/QuoteStep';
import Group from '@atoms/layout/form/Group';
import CardPaymentForm from '@organisms/forms/CardPaymentForm/CardPaymentForm.container';
import DurationPriceChangeModal from '@organisms/modals/DurationPriceChangeModal';
import SeamlessPriceDisplay from '@molecules/blocks/SeamlessPriceDisplay';
import PricingComponent from '@molecules/blocks/PricingComponent.payment';

import { FormErrorContainer, TextContainer } from './assets/styles';

import { ComponentProps } from './Payment.container';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.tz.setDefault('Europe/London');

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Payment: React.FC<ComponentProps> = ({
  isImmediateStart,
  startDate,
  endDate,
  paymentMethod,
  cardHolderName,
  setCardHolderName,
  productEmail,
  paymentError,
  isSubscription,
  pollForQuoteStatus,
  setPaymentLoader,
  productType,
}) => {
  const [showDurationChangeModal, toggleDurationChangeModal] = useState(false);
  const [paypalErrorMsg, setPaypalErrorMsg] = useState<string | null>(null);
  const paymentErrorText = paymentError || paypalErrorMsg;
  const [showPriceAfterPass, setShowPriceAfterPass] = useState(false);

  const handleToggle = () => {
    setShowPriceAfterPass(!showPriceAfterPass);
  };

  const checkDurationHasChanged = () => {
    if (isImmediateStart && !isSubscription) {
      const paymentTime = 25;
      const now = dayjs();
      const cutoff = now.add(paymentTime, 'seconds');
      const previousDuration = Math.ceil(dayjs.duration(endDate?.diff(startDate) ?? 0).as('hour'));
      const currentDuration = Math.ceil(dayjs.duration(endDate?.diff(cutoff) ?? 0).as('hour'));
      if (previousDuration !== currentDuration) {
        toggleDurationChangeModal(true);
        return true;
      }
    }

    return false;
  };

  const checkSubscriptionIsInPast = () => {
    if (isSubscription && !isImmediateStart) {
      if (dayjs.tz(startDate, 'Europe/London').isBefore(dayjs().tz('Europe/London'))) {
        toggleDurationChangeModal(true);
        Sentry.captureMessage('Future subscription start date becomes invalid');
        return true;
      }
    }

    return false;
  };

  const query = useQuery();

  const checkVisitedByStripeCallback = () => {
    setPaymentLoader(true);
    const paymentIntentClientSecret = query.get('payment_intent_client_secret');
    const paymentIntent = query.get('payment_intent');
    const redirectStatus = query.get('redirect_status');

    setPaypalErrorMsg(null);

    if (paymentIntentClientSecret && paymentIntent && redirectStatus) {
      if (redirectStatus === 'failed') {
        setPaypalErrorMsg('Something went wrong, please try a different payment method.');
      } else {
        pollForQuoteStatus();
        return;
      }
    }
    setPaymentLoader(false);
  };

  useEffect(() => {
    checkVisitedByStripeCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuoteStep
      id='payment-component-quoteStep'
      includeStepPrefix={false}
      stepTitle='Buying your policy'
      stepSubtitle='Please provide your payment information below to complete your purchase.'
    >
      {showDurationChangeModal && (
        <DurationPriceChangeModal visible onConfirm={() => toggleDurationChangeModal(false)} />
      )}
      {paymentMethod === '' && (
        <Group id='payment-text-priceDisplay'>
          {productType === 'seamless' ? (
            <SeamlessPriceDisplay
              leftActive={!showPriceAfterPass}
              handleToggle={handleToggle}
              showPolicyDetails
              renderIPTText
            />
          ) : (
            <PricingComponent
              quoteSummaryHeader={
                isSubscription ? 'Your monthly subscription' : 'A single payment of'
              }
              quoteSummaryFooter={
                <TextContainer id='payment-text-insuranceTax'>
                  Your price includes insurance premium tax at 12% (where applicable)
                </TextContainer>
              }
              isTelematics={productType === 'newdriver'}
            />
          )}
        </Group>
      )}
      {paymentErrorText && (
        <Group>
          <FormErrorContainer>
            <InformationBox id='payment-text-paymentError' type='error'>
              {paymentErrorText}
            </InformationBox>
          </FormErrorContainer>
        </Group>
      )}
      <Group>
        <CardPaymentForm
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - This will need some major typescript work to resolve, involving generics/etc.
          // N.B prop spreading to children is evil (in CardPaymentForm)...
          checkDurationHasChanged={checkDurationHasChanged}
          cardHolderName={cardHolderName}
          setCardHolderName={setCardHolderName}
          productEmail={productEmail}
          checkSubscriptionIsInPast={checkSubscriptionIsInPast}
        />
      </Group>
      {paymentErrorText && (
        <Group>
          <FormErrorContainer>
            <InformationBox id='payment-text-paymentErrorBottom' type='error'>
              {paymentErrorText}
            </InformationBox>
          </FormErrorContainer>
        </Group>
      )}
    </QuoteStep>
  );
};

export default Payment;
