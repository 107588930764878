import React from 'react';
import QuoteStep from '@templates/QuoteStep';
import { InformationBox, PillButton } from '@rentecarlo/component-library';
import { ImportantBoxContainer, IpidContainer, Text } from './styles';
import NewDriverCard from './NewDriverCard';

type NewDriverCrossSellProps = {
  isQuoteForMyself: boolean;
  setNewDriverCrossSellModalIsOpen: (value: boolean) => void;
  newDriverCrossSellResults: { price: number; passedUW: true };
};

const NewDriverCrossSell = ({
  isQuoteForMyself,
  setNewDriverCrossSellModalIsOpen,
  newDriverCrossSellResults,
}: NewDriverCrossSellProps): JSX.Element => {
  return (
    <QuoteStep
      stepTitle={`Sorry, we can't offer ${isQuoteForMyself ? 'you' : 'them'} this insurance`}
      stepSubtitle={`Unfortunately, we're unable to provide the insurance you've quoted for on this occasion. However, ${
        isQuoteForMyself ? 'you' : 'they'
      } are eligible for our New Driver insurance subscription. See below for more details.`}
      titleMarginBottom='16px'
    >
      <ImportantBoxContainer>
        <InformationBox id='rejection-text-information' type='important'>
          Please note that our Customer Care agents are not able to change this decision or provide
          specific reasoning.
        </InformationBox>
      </ImportantBoxContainer>
      <Text id='rejection-text-eligibility'>
        However {isQuoteForMyself ? 'you' : 'they'} are eligible for our New driver insurance
        subscription:
      </Text>
      <NewDriverCard
        setNewDriverCrossSellModalIsOpen={setNewDriverCrossSellModalIsOpen}
        newDriverCrossSellResults={newDriverCrossSellResults}
        isQuoteForMyself={isQuoteForMyself}
      />
      <IpidContainer>
        <PillButton
          id='rejection-button-IPIDPill'
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_ASSETS_URL}/docs/newdriver/New_Driver_Insurance_Product_Information_Document.pdf`,
              '_blank',
            );
          }}
        >
          Read our Product Information Guide
        </PillButton>
      </IpidContainer>
    </QuoteStep>
  );
};

export default NewDriverCrossSell;
