import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { fetchRestrictedPeriodsRequest } from '@reducer/config/actions';
import { resetQuote } from '@reducer/quote/actions';
import { getCurrentRestrictedPeriod } from '@reselectors/restrictedPeriod';
import { saveQuote, updatePrice } from '@redux/reducer/quote/save.actions';
import { setPaymentMethod, setProduct, setSubscription } from '@reducer/quote/product.actions';
import { clearAlternativeProducts } from '@redux/reducer/quote/alternativeProducts.actions';

import analyticsClient from '@utils/analytics';
import Rejection from './Rejection';

const mapState = (state) => ({
  rejectionReasons: state.quote.save.rejectionReasons,
  restrictedPeriod: getCurrentRestrictedPeriod(state),
  product: state.quote.product.productType,
  isSubscription: state.quote.product.isSubscription,
  alternativeProducts: state.quote.alternativeProducts,
  isQuoteForMyself: state.account.customer.isQuoteForMyself,
});

const mapDispatch = (dispatch) => ({
  fetchRestrictedPeriods: () => dispatch(fetchRestrictedPeriodsRequest()),
  updateQuoteToNewDriver: () => {
    dispatch(setProduct('newdriver'));
    dispatch(saveQuote());

    // Remove the /rejection page from history to allow customers to go back and edit their newdriver quote
    dispatch(replace('/important-information'));
  },
  updateQuoteToTempCover: (price, selectDuration) => {
    dispatch(setSubscription(false));
    dispatch(updatePrice(price));
    dispatch(saveQuote());

    if (selectDuration) {
      dispatch(replace('/indicative-price'));
    } else {
      dispatch(replace('/car'));
    }

    dispatch(clearAlternativeProducts());
    analyticsClient.trackEvent('web_temp_cover_cross_sell_click', {
      option: selectDuration ? 'custom duration' : '30 days',
    });
  },
});

export default connect(mapState, mapDispatch)(Rejection);
