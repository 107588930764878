import React, { ReactElement } from 'react';
import { FieldContainer } from '@rentecarlo/component-library';
import fieldStyleProps from '@services/fieldStyleProps';

import SelectNew from './SelectNew';

interface Props {
  id: string;
  options?: {
    name: string;
    value: string;
  }[];
  placeholder: string;
  input: {
    onChange: (name: string) => void;
    value: string;
  };
  meta: {
    error?: string;
    active?: boolean;
    submitFailed?: boolean;
    touched?: boolean;
    pristine?: boolean;
    valid?: boolean;
  };
  disabled: boolean;
}

const SelectNewField = ({
  id,
  options = [],
  placeholder,
  input: { onChange, value },
  meta: { error, active, submitFailed, touched, pristine, valid },
  disabled,
}: Props): ReactElement => {
  const { borderBottomColor } = fieldStyleProps(
    disabled,
    active,
    valid,
    ((touched && !pristine) || submitFailed) && error && !active,
  );
  return (
    <FieldContainer
      id={id}
      showErrorContainer={submitFailed && error}
      showError={((!pristine && touched) || submitFailed) && error && (!active || submitFailed)}
      error={error}
    >
      <SelectNew
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        options={options}
        borderBottomColor={borderBottomColor}
      />
    </FieldContainer>
  );
};

export default SelectNewField;
