import styled, { css } from 'styled-components';
import colors from '@config/colors';

export const Text = styled.p`
  color: ${colors.black};
  text-align: center;
  font-family: ${({ theme }) => theme.standard};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 161.8%;
  margin-top: 16px;
`;

export const PoundSign = styled.p`
  color: ${colors.black};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'proxima-soft';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 161.8%;
`;

export const Container = styled.div<{ showPolicyDetails: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: ${(props) => (props.showPolicyDetails ? '5px 5px 0px 0px' : '5px')};
  border: 1px solid ${colors.grey.lines.light};
  ${(props) =>
    props.showPolicyDetails &&
    css`
      border-bottom: 0px;
    `}
  box-shadow: ${(props) => (props.showPolicyDetails ? '0px' : `0px 0px 10px 5px ${colors.shadow}`)};
  padding-bottom: 16px;
  margin-top: 16px;
`;

export const PriceText = styled.div`
  color: ${colors.black};
  font-family: Graphie;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 49.5px */
`;

export const RowContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

export const ChipRowContainer = styled(RowContainer)`
  gap: 8px;
`;

export const PerMonth = styled(Text)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-weight: 700;
`;

export const FirstMonth = styled.div`
  display: flex;
  padding: 4px 16px;
  margin: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 5px 5px 0px 0px;
  background: ${colors.green.dark};
  color: ${colors.white};
  font-weight: 700;
`;

export const PassPriceEstimateText = styled(Text)`
  margin-top: 0px;
`;

export const QuoteDetails = styled.div<{ showPolicyDetails?: boolean }>`
  background-color: ${colors.grey.background.light};
  display: flex;
  flex-direction: column;
  padding 0px 70px;
  width: 100%;
  margin-top: 0px;
  border-radius 0px 0px 5px 5px;
  border: 1px solid ${colors.grey.lines.light};
  ${(props) =>
    props.showPolicyDetails &&
    css`
      border-top: 2px solid ${colors.easternBlue};
    `}
`;

export const Divider = styled.div`
  background-color: ${colors.grey.lines.light};
  height: 1px;
  width: 100%;
  align-self: center;
`;

export const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 5px;
`;

export const OldValue = styled.div`
  color: ${colors.blue.important};
  text-decoration: line-through;
  font-family: Graphie;
  font-size: 16px;
  font-weight: bold;
  height: 16px;
  width: 72px;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  display: flex;
`;

export const IPTText = styled.div`
  margin: 0 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19.42px;
`;
