import { createSelector } from 'reselect';

import { RootState } from '@redux/reducer';
import convertProductType from '@services/convertProductType';

import { OptimizelyAttributes } from '@redux/reducer/config/types';

import { PaymentMethod } from '@utils/types';

export interface OptimizelyOverrideAttributes extends OptimizelyAttributes {
  product: string;
  paymentMethod: PaymentMethod | null;
  isSubscription: boolean;
  isLoggedIn: boolean;
  buyingFor: string;
}

export const getOptimizelyUserIdentity = (state: RootState): string =>
  state.config.optimizelyAttributes.user_identity;

const getOptimizelyAttributes = (state: RootState) => state.config.optimizelyAttributes;
const getProduct = (state: RootState) => state.quote.product;
const getIsDriverOwner = (state: RootState) => state.quote.driver.isDriverOwner;
const getIsLoggedIn = (state: RootState) => state.account.login.loggedIn;

const getIsQuoteForMyself = (state: RootState) => state.account.customer.isQuoteForMyself;
const getCustomerRelationToBuyer = (state: RootState) => state.account.customer.relationToBuyer;

export const optimizelyOverrideAttributes = createSelector(
  getOptimizelyAttributes,
  getProduct,
  getIsDriverOwner,
  getIsLoggedIn,
  getIsQuoteForMyself,
  getCustomerRelationToBuyer,
  (
    optimizelyAttributes,
    product,
    isDriverOwner,
    isLoggedIn,
    isQuoteForMyself,
    relationToBuyer,
  ): OptimizelyOverrideAttributes => ({
    ...optimizelyAttributes,
    product: convertProductType(product.productType, isDriverOwner),
    paymentMethod: product.paymentMethod,
    isSubscription: product.isSubscription,
    isLoggedIn,
    buyingFor: isQuoteForMyself ? 'myself' : relationToBuyer ?? 'someone-else',
  }),
);
