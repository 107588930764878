import * as Sentry from '@sentry/browser';
import { compose } from 'redux';
import { merge } from 'lodash';
import mapValues from 'lodash/mapValues';

import dayjs from 'dayjs';

import persistState, { mergePersistedState, transformState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/sessionStorage';

const transformationMap = {
  account: {
    customer: {
      birthdate: dayjs,
    },
    login: {
      tokenExpiration: dayjs,
    },
  },
  quote: {
    driver: {
      birthdate: dayjs,
    },
    owner: {
      birthdate: dayjs,
    },
    duration: {
      startDateTime: dayjs,
      endDateTime: dayjs,
    },
    purchasedQuote: {
      startDate: dayjs,
    },
    drivingTest: {
      drivingTestDate: dayjs,
    },
  },
};

function transform(state, map = transformationMap) {
  return mapValues(state, (value, key) => {
    if (value !== undefined && value !== null && key in map) {
      const subMap = map[key];
      if (typeof subMap === 'function') return subMap(value);
      return transform(value, subMap);
    }
    return value;
  });
}

export const persistedReducer = compose(
  mergePersistedState((initialState, persistedState) => merge({}, initialState, persistedState)),
);

export const down = (state) => {
  return {
    account: state.account,
    lookup: state.lookup,
    quote: {
      ...state.quote,
      payment: {
        termsAndConditionsAccepted: state.quote.payment.termsAndConditionsAccepted,
      },
      save: {
        ...state.quote.save,
        expiry: dayjs().add(2, 'hour'),
      },
    },
    config: {
      ...state.config,
      amplifyLoaded: false,
    },
    app: state.app,
    pages: state.pages,
  };
};

export const up = (state) => {
  if (!state) {
    return undefined;
  }

  if (dayjs(state.quote.save.expiry) < dayjs()) {
    Sentry.addBreadcrumb({
      message: 'Session storage expired',
      category: 'redux',
      level: 'info',
      data: { expiry: state?.quote?.save?.expiry },
    });

    // Reset state, but keep state.quote.purchasedQuote
    return {
      quote: {
        purchasedQuote: state?.quote?.purchasedQuote,
      },
    };
  }
  return transform(state);
};

const storage = compose(transformState(down, up))(adapter(window.sessionStorage));

export const sessionStorageEnhancer = compose(persistState(storage, 'redux'));

export default persistedReducer;
