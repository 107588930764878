import styled from 'styled-components';
import colors from '@config/colors';

export const AddLink = styled.div`
  color: ${colors.blue.royal};
  font-family: 'proxima-soft';
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  text-wrap: nowrap;
  padding: 4px;
`;

export const Icon = styled.img`
  margin-right: 3px;
`;

export const LinkContainer = styled.a`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const CodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CodeArea = styled.div``;

export const Divider = styled.div`
  border-top-style: dashed;
  border-color: ${colors.grey.lines.light};
  border-top-width: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

export const CodeInputContainer = styled.div`
  width: 150px;
  line-height: 16px;
`;

export const AuthenticationButtons = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const DiscountMessageContainer = styled.div`
  margin-top: 15px;
  width: 80%;
`;

export const TermsAndConditionsLink = styled.u``;

export const AppliedText = styled.div`
  height: 16px;
  color: #364344;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  text-align: left;
`;

export const RemoveLink = styled.a`
  height: 16px;
  color: #d0021b;
  font-size: 10px;
  line-height: 16px;
  cursor: pointer;
`;
