import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@redux/reducer';
import SeamlessField from './SeamlessOffer';
import NewDriverProductChoice from './NewDriverProductChoice';

const ProductChoice: React.FC = () => {
  const productType = useSelector((state: RootState) => state.quote.product.productType);
  const showSeamless = productType === 'ldp' || productType === 'seamless';

  return showSeamless ? <SeamlessField /> : <NewDriverProductChoice />;
};
export default ProductChoice;
