/* eslint-disable no-duplicate-case, no-param-reassign */
import { remoteToLocalFields } from '@services/transformers/customers';
import {
  SET_CUSTOMER_FIELD,
  SET_DRIVER_FROM_QUOTE,
  UPDATE_DRIVER_FROM_QUOTE,
  CUSTOMER_FETCH_ME_REQUEST,
  CUSTOMER_FETCH_ME_SUCCESS,
  CUSTOMER_SAVE_REQUEST,
  CUSTOMER_SAVE_SUCCESS,
  CUSTOMER_SAVE_FAILURE,
  CustomerActions,
  HandleSaveFailuresActionTypes,
  SET_QUOTE_FOR_MYSELF,
  SET_SHOULD_SHOW_SIDEBAR,
} from './customer.actions';
import { types as loginTypes } from './login.actions';
import { types as registerTypes, RegisterActiontypes } from './register.actions';
import { aboutYou } from '../pages/actions';
import { PageActions } from '../pages/types';
import deepUpdate from '../../../services/deepUpdate';
import { CustomerState } from './customerTypes';

const handleSaveFailure = (action: HandleSaveFailuresActionTypes, state: CustomerState) => {
  state = deepUpdate(state, 'loading', false);

  if (!action.error) return state;
  // serializers.ValidationError in DRF sends the error text and code inside an array instead of a string
  if (typeof action.error === 'object' && action.error[0] === "This account can't be edited") {
    state = deepUpdate(state, 'canEdit', false);
    return state;
  }
  switch (action.error.code) {
    case 'LICENCENOTFOUND':
      return deepUpdate(state, 'licence.cassieType', 'N');
    case 'UNCONFIRMEDPROVISIONAL':
      return deepUpdate(state, 'licence.cassieType', 'P');
    case 'FR13':
      return deepUpdate(
        state,
        'failureReasons',
        state.failureReasons.concat(state.failureReasons, {
          reason: 'FR13',
        }),
      );
    case 'UNEDITABLE':
      return deepUpdate(state, 'canEdit', false);
    default:
      return state;
  }
};

// Reducer
export const initialState: CustomerState = {
  uuid: null,
  title: '',
  firstName: '',
  lastName: '',
  birthdate: null,
  age: null,
  email: '',
  occupation: '',
  occupationType: '',
  phoneNumber: '',
  postcode: '',
  canEdit: true,
  loading: false,
  hasEdited: false,
  hasEditedPersonalDetails: false,
  display30DayEditModal: false,
  ncb: {
    haveNCB: null,
    level: null,
  },
  quotes: [],
  insuredHours: null,
  licence: {
    country: '',
    number: '',
    type: '',
    cassieType: '',
    fullLicenceConfirmed: null,
    fullLicenceDate: null,
  },
  isNew: true,
  clearanceLevel: '',
  csiNCB: null,
  failureReasons: [],
  migratedLDP: false,
  source: '',
  instructorId: '',
  statedTestPassDate: null,
  hasConversionBonus: false,
  contactPreferences: {
    email: true,
    telephone: true,
  },
  isQuoteForMyself: true,
  relationToBuyer: null,
  displaySideBar: true,
};
export type CustomerActionTypes = PageActions | CustomerActions | RegisterActiontypes;

export default function customerReducer(
  state = initialState,
  action = {} as CustomerActionTypes,
): CustomerState {
  switch (action.type) {
    case SET_CUSTOMER_FIELD:
      return deepUpdate(state, action.field, action.value);
    case CUSTOMER_FETCH_ME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMER_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case registerTypes.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case registerTypes.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMER_FETCH_ME_SUCCESS:
    case CUSTOMER_SAVE_SUCCESS:
      return {
        ...state,
        ...remoteToLocalFields(action.data, state),
        loading: false,
      };

    case registerTypes.REGISTER_SUCCESS:
      return {
        ...state,
        ...remoteToLocalFields(action.data, state),
        isNew: false,
        loading: false,
      };

    case CUSTOMER_SAVE_FAILURE:
    case registerTypes.REGISTER_FAILURE:
      return {
        ...handleSaveFailure(action, state),
        loading: false,
      };
    case loginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isNew: false,
      };
    case loginTypes.LOGOUT_SUCCESS:
      return initialState;
    case SET_DRIVER_FROM_QUOTE:
      return {
        ...state,
        uuid: action.uuid,
        title: action.quote.driver.title,
        firstName: action.quote.driver.firstName,
        lastName: action.quote.driver.lastName,
        birthdate: action.quote.driver.birthdate,
        email: action.quote.driver.email,
        occupation: action.quote.driver.occupation,
        occupationType: action.quote.driver.occupationType,
        phoneNumber: action.quote.driver.phoneNumber,
        ncb: {
          haveNCB: action.quote.noClaimsBonus.haveNCB,
          level: action.quote.noClaimsBonus.level,
        },
        licence: {
          country: action.quote.licence.country,
          number: action.quote.licence.licenceNumber,
          cassieType: action.quote.licence.cassieType,
          type: action.quote.licence.type,
          fullLicenceConfirmed: action.quote.licence.fullLicenceConfirmed,
          fullLicenceDate: action.quote.licence.fullLicenceDate,
        },
        isNew: true,
      };
    case UPDATE_DRIVER_FROM_QUOTE:
      return {
        ...state,
        occupation: action.quote.driver.occupation,
        occupationType: action.quote.driver.occupationType,
        phoneNumber: action.quote.driver.phoneNumber,
      };
    case aboutYou.SUBMITTED:
      return {
        ...state,
        source: action.payload.source,
      };
    case SET_QUOTE_FOR_MYSELF:
      return {
        ...state,
        isQuoteForMyself: action.isQuoteForMyself,
        relationToBuyer: action.isQuoteForMyself
          ? initialState.relationToBuyer
          : state.relationToBuyer,
      };
    case SET_SHOULD_SHOW_SIDEBAR:
      return {
        ...state,
        displaySideBar: action.displaySideBar,
      };
    default:
      return state;
  }
}
