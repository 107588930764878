import React from 'react';
import styled from 'styled-components';

import { CardUtils, TickIcon, IconList, Button, Chip } from '@rentecarlo/component-library';
import OneOffIcon from '@assets/one-off-icon.svg';
import { AlternativeProductTrueResult } from '@redux/reducer/quote/alternativeProducts';
import { useOptimizelyTrack } from '@hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(CardUtils.StyledCard)`
  border: 1px solid ${({ theme }) => theme.fieldSecondary};
  border-radius: 0 8px 8px;
  padding: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4px;
  padding: 16px 0;

  border-bottom: 2px solid #16b3b3;
`;

const ChipsContainer = styled.div`
  margin-top: 8px;

  display: flex;
  gap: 16px;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.textPrimary};

  font-family: ${({ theme }) => theme.standard};
  font-size: 14px;
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.textPrimary};

  font-family: ${({ theme }) => theme.standard};
  font-size: 18px;
`;

const Price = styled.span`
  font-size: 32px;
  font-family: ${({ theme }) => theme.title};
  font-weight: bold;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  gap: 24px;
  padding: 24px;

  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 0 0 8px 8px;
`;

const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};

  font-weight: bold;
  margin: -5px 0;
`;

interface Props {
  tempCoverCrossSellResults: AlternativeProductTrueResult;
  isQuoteForMyself: boolean;
  updateQuoteToTempCover: () => void;
}

const TempCoverCard: React.FC<Props> = ({
  tempCoverCrossSellResults,
  updateQuoteToTempCover,
  isQuoteForMyself,
}) => {
  const sendEvent = useOptimizelyTrack();

  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <Title id='rejection-title-tempCoverCard'>Pay-as-you-go Insurance</Title>
          <SubTitle id='rejection-subtitle-tempCoverCard'>
            <Price id='rejection-text-tempCoverCardPrice'>
              £{tempCoverCrossSellResults.price.toFixed(2)}
            </Price>{' '}
            for 30 days
          </SubTitle>
          <ChipsContainer>
            <Chip icon={OneOffIcon} text='One-off Short Term Policy' />
          </ChipsContainer>
        </TitleContainer>
        <Content>
          <IconList
            id='rejection-container-tempCoverCardTracking'
            icon={TickIcon}
            items={[
              <Text id='rejection-text-tempCoverCardTracking'>
                Instant 30-day cover with the flexibility to extend as needed
              </Text>,
              <Text id='rejection-text-tempCoverCardPriceReview'>
                Perfect if {isQuoteForMyself ? "you've" : "they've"} only got occasional access to a
                car
              </Text>,
              <Text id='rejection-text-tempCoverCardNCB'>
                Great for sharing or borrowing a car without committing to a long-term policy
              </Text>,
            ]}
          />
          <Button
            id='rejection-button-tempCoverContinue'
            height={40}
            onClick={() => {
              updateQuoteToTempCover();
              sendEvent('TEMP_COVER_CROSS_SELL_30_DAYS');
            }}
          >
            Buy 30 days of cover
          </Button>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default TempCoverCard;
