/*
 * Reselectors for Quote conditions.
 * Reselectors are memoized selectors.
 * Selectors as the functions that retrieve snippets of the Redux state for our React components.
 * Using memoization, we can prevent unnecessary rerenders and recalculations of derived data.
 * Importantly we can keep business logic out of components.
 */
import { createSelector } from 'reselect';
import indicativeDurations, { Durations } from '@config/indicativeDurations';
import { isBetween22and5 } from '@services/date';
import { RootState } from '@redux/reducer';
import { getCurrentRestrictedPeriod, isRestrictedPeriod } from '@reselectors/restrictedPeriod';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getProductType = (state: RootState) => state.quote.product.productType;
const getStartDateTime = (state: RootState) => state.quote.duration.startDateTime;

export const createTypedDraftSafeSelector = createDraftSafeSelector.withTypes<RootState>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIndicativeDurationsConfig = createSelector<any, Durations>(
  [getProductType, getStartDateTime, isRestrictedPeriod, getCurrentRestrictedPeriod],
  (productType, startDateTime, isRestricted, currentRestrictedPeriod) => {
    const durations = indicativeDurations;

    if (isRestricted) {
      if (currentRestrictedPeriod[0].min_duration >= 24) {
        durations.csi.hours.locked = true;
        durations.ldp.hours.locked = true;
      }
    }

    if (isBetween22and5(startDateTime)) {
      durations.csi.hours.locked = true;
      durations.ldp.hours.locked = true;
    }

    if (productType === 'ldp') {
      return durations.ldp;
    }

    return durations.csi;
  },
);

// TEMP_REDUCE_INDICATIVE_PRICE_OPTIONS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getReducedIndicativeDurationsConfig = createSelector<any, Durations>(
  [getProductType, isRestrictedPeriod, getCurrentRestrictedPeriod],
  (productType, isRestricted, currentRestrictedPeriod) => {
    const durations = indicativeDurations.getDurationConfig(productType);

    if (isRestricted) {
      if (currentRestrictedPeriod[0].min_duration >= 24) {
        durations.hours.locked = true;
      }
    }

    return durations;
  },
);
