import React from 'react';

import { PageModal, IconList } from '@rentecarlo/component-library';
import TealQuestionMark from '@assets/teal-question-mark-icn.svg';
import BlackTick from '@assets/black-tick.svg';
import { ComponentType, useComponentId } from '@hooks';
import styled from 'styled-components';
import colors from '@config/colors';

interface Props {
  show: boolean;
  close: () => void;
}

const Image = styled.img`
  align-self: center;
`;

const Title = styled.h2`
  color: ${colors.black};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Graphie;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: -0.26px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`;

const Text = styled.p`
  color: ${colors.black};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.31px;
`;

const Description = styled(Text)`
  text-align: center;
`;

const TextHeaders = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.23px;
  color: ${({ theme }) => theme.textPrimary};
`;

export const SeamlessPassPriceModal: React.FC<Props> = ({ show, close }) => {
  const closeText = 'Close';
  const idCreator = useComponentId();
  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Container>
        <Image src={TealQuestionMark} />
        <Title id={idCreator(ComponentType.TITLE, 'PaperDocsModalTitle')}>
          Why does my price go up after I pass?
        </Title>
        <Description id={idCreator(ComponentType.TEXT, 'PaperDocsModalDescription')}>
          Young drivers often see prices rise 2 to 3 times after passing due to higher risks in
          their first year driving solo.
          <br /> <br /> Here’s how we support you:
        </Description>
        <IconList
          icon={BlackTick}
          id='SeamlessPassPriceModal-iconList'
          items={[
            <Text id='productChoice-text-seamlessModalInfoListOne'>
              <TextHeaders>First month at your learner price</TextHeaders>
              Enjoy your learner rate for the first month after you pass.{' '}
            </Text>,
            <Text id='productChoice-text-seamlessModalInfoListTwo'>
              <TextHeaders>Save by driving safe</TextHeaders>
              Use our telematics app to improve your driving and save up to 20% every 3 months.
            </Text>,
            <Text id='productChoice-text-seamlessModalInfoListThree'>
              <TextHeaders>Competitive price</TextHeaders>
              Go and compare! Our prices are one of the best on the market!{' '}
            </Text>,
          ]}
        />
      </Container>
    </PageModal>
  );
};

export default SeamlessPassPriceModal;
