/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import {
  quickSelectIndicativePrice,
  getQuickSelectPrices,
} from '@reducer/quote/indicativePrice.actions';
import {
  getIndicativeDurationsConfig,
  getReducedIndicativeDurationsConfig,
} from '@reselectors/price';
import {
  getCurrentRestrictedPeriod,
  isRestrictedPeriod,
} from '@redux/reselectors/restrictedPeriod';

import { resetDuration } from '@reducer/quote/duration.actions';
import { RootState } from '@redux/reducer';

import IndicativePriceInput from './IndicativePriceInput';

const mapStateToProps = (state: RootState) => {
  const {
    app: { isMobile },
    quote: {
      duration: { endDateTime, endChoiceSelected, startDateTime },
      indicativePrice: { price, loading },
      product: { productType },
    },
  } = state;

  const endChoiceSelectedValue = endChoiceSelected && endChoiceSelected.value;

  return {
    endChoiceSelected: endChoiceSelectedValue,
    price,
    durationsConfig: getIndicativeDurationsConfig(state),
    reducedDurationsConfig: getReducedIndicativeDurationsConfig(state),
    getProductType: productType,
    isLoadingPrices: loading,
    endDateTime,
    startDateTime,
    isMobile,
    loggedIn: state.account.login.loggedIn,
    isRestrictedPeriod: isRestrictedPeriod(state),
    getCurrentRestrictedPeriod: getCurrentRestrictedPeriod(state),
  };
};

const resetAndGoToDuration = (dispatch: Dispatch) => {
  dispatch(resetDuration());
  dispatch(push('duration-of-cover'));
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectDuration: (duration) => dispatch(quickSelectIndicativePrice(duration)),
  getQuickSelectPrices: (isSubscription: boolean) => dispatch(getQuickSelectPrices(isSubscription)),
  goToDurationOfCover: () => resetAndGoToDuration(dispatch),
});

const IndicativePriceInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndicativePriceInput);

export default IndicativePriceInputContainer;
